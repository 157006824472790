/*
*	- ALTIJD dit bestand gebruiken om libraries of plugin te initialiseren, of kleine snippets toe te voegen.
*	- Heb je echt specifieke custom code (WooCommerce bijvoorbeeld)? Maak /dev/_{function}.js en include via global.js
*	- Code conventions: http://make.wordpress.org/core/handbook/coding-standards/javascript/
*/

jQuery( document ).ready( function () {

	/*------------------------------------------------------------------------*/
	/*  Strict modus
	/*  	- http://www.nczonline.net/blog/2012/03/13/its-time-to-start-using-javascript-strict-mode/
	/*------------------------------------------------------------------------*/

	'use strict';

	var $body = jQuery('body');
	var body_min_width = 750;


	/*------------------------------------------------------------------------*/
	/*  Small screens viewport fade-in
	/*------------------------------------------------------------------------*/

	if ( jQuery(window).width() < body_min_width ) {

		var viewportMeta = '<meta name="viewport" content="width=';
			viewportMeta += body_min_width;
			viewportMeta += '">';

		jQuery('head meta[name=viewport]').remove();
		jQuery('head').append( viewportMeta ).next('body').addClass('viewport-loaded');

	} else {

		$body.addClass('viewport-loaded');

	}


	/*------------------------------------------------------------------------*/
	/*  Share link on knowledgebase select all text on input
	/*------------------------------------------------------------------------*/

	jQuery('.input-copy').on('click', function () {
		jQuery(this).select();
	});


	/*------------------------------------------------------------------------*/
	/*  Picture options dropdown
	/*------------------------------------------------------------------------*/

	var $pictureOptionsClose = jQuery('#picture-options-close');

	jQuery('.dropdown__button').on('click', function() {

		var currentElement = jQuery(this);

		var targetElement  = currentElement.next('.dropdown__content');
		var targetHeight   = targetElement.children('.dropdown__content__inner').outerHeight(true);

		var activeSibling  = false;

		targetElement.removeClass('wait-for-closing-dropdown');


		/* Other dropdown is active */

		if ( currentElement.siblings('.dropdown__button').hasClass('active') ) {

			activeSibling = true;
			var $activeButton = currentElement.siblings('.dropdown__button.active');

			var activeDropdown = $activeButton.data('dropdown');
			var $activeElement = jQuery( '#' + activeDropdown );

			// Close other active dropdown
			$activeButton.removeClass('active');
			$activeElement.removeClass('wait-for-closing-dropdown');
			$activeElement.css('max-height', 0);

		}

		/* Open / Close clicked dropdown */

		if ( ! currentElement.hasClass('active') ) {

			// Delay clicked dropdown ( active dropdown has to close first )
			if ( activeSibling ) {
				targetElement.addClass('wait-for-closing-dropdown');
			}

			// Open clicked dropdown
			currentElement.addClass('active');
			targetElement.css({
				'max-height' : targetHeight,
				'will-change' : 'max-height'
			});

			// Close button ^
			$pictureOptionsClose.addClass('active');

		} else {

			// Close active dropdown
			currentElement.removeClass('active');
			targetElement.removeClass('wait-for-closing-dropdown');
			targetElement.css('max-height', 0);

			// Close button ^
			$pictureOptionsClose.removeClass('active');

		}


	});

	$pictureOptionsClose.on('click', function() {

		var $this = jQuery(this);

		$this.removeClass('active');

		jQuery('.dropdown__button.active').removeClass('active');

		jQuery('.dropdown__content').each( function() {

			jQuery(this).removeClass('wait-for-closing-dropdown').css('max-height', 0);

		});


	});


	/*------------------------------------------------------------------------*/
	/*  Filter dropdown position
	/*------------------------------------------------------------------------*/

	var $filter = jQuery('.filter');

	if ( $filter.length ) {

		var filterHeight 		= $filter.height();
		var filtersTopOffset 	= $filter.offset().top;

		var dropdownsPosition 	= function() {

			jQuery('.filter-item').each( function() {

				var $this = jQuery(this);

				var filterTopOffset = $this.offset().top;

				var filterLeftOffset 			= $filter.offset().left;
				var filterItemLeftOffset		= $this.offset().left;
				var dropdownLeftOffsetMargin 	= Math.round( filterItemLeftOffset - filterLeftOffset );

				$this.find('.filter-item-drowdown')
					.attr('data-width', $this.find('.filter-item-drowdown').innerWidth())
					.attr('data-margin-left', dropdownLeftOffsetMargin)
					.css({
						'top': $this.height() + ( filterTopOffset - filtersTopOffset ) + 'px',
						'margin-left': dropdownLeftOffsetMargin + 'px',
						'max-width': 'calc( 100% - ' + ( dropdownLeftOffsetMargin ) + 'px )'
					});

			});

			filterHeight = $filter.height();

		}

		jQuery( window ).load(function() {

			dropdownsPosition()

			/* Make dropdown full-width if it's overflowing */

			var filterWidth = $filter.innerWidth();

			jQuery('.filter-item-drowdown').each( function() {

				var $this = jQuery(this);

				// If element fits in container and width is smaller than 320px

				if ( $this.data('margin-left') + $this.data('width') > filterWidth && $this.innerWidth() < 320 ) {

					$this.addClass('full-width');

				}

			});

		});

		jQuery( window ).resize(function() {

			if ( filterHeight !== $filter.height() ) {

				dropdownsPosition();

			}

		});


		/*------------------------------------------------------------------------*/
		/*  Open dropdown if child is focused
		/*------------------------------------------------------------------------*/

		jQuery('html.no-touchevents .filter-item-drowdown input, html.no-touchevents .filter-item-drowdown select').focus(function() {

			jQuery(this).closest('.filter-item').addClass('active').mouseleave(function() {

				jQuery(this).removeClass('active');

			});

		}).focusout(function() {

			jQuery(this).closest('.filter-item').removeClass('active');

		});


		/*------------------------------------------------------------------------*/
		/*  Close dropdown on click
		/*------------------------------------------------------------------------*/

		jQuery('.filter-item-button').on('click', function(){

			var $this = jQuery(this);
			var $parent = $this.parent();
			var thisActive = false;

			if (  $parent.hasClass('active') ) {
				thisActive = true;
			}

			jQuery('.filter-item').each(function() {
				 jQuery(this).removeClass('active');
			});

			if ( ! thisActive ) {
				$parent.addClass('active');
			}

		});


		/*------------------------------------------------------------------------*/
		/*  Open dropdown
		/*------------------------------------------------------------------------*/

		jQuery('html.no-touchevents .filter-item').mouseenter(function(event) {

			jQuery(this).addClass('active');

		}).mouseleave(function(event) {

			jQuery(this).removeClass('active');

		});

	}

	/*------------------------------------------------------------------------*/
	/*  Grid layout selector
	/*------------------------------------------------------------------------*/

	jQuery('input[name="layout-grid-column"]').on('change', function() {

		var $this = jQuery(this);

		var selectedLayout = $this.attr('value')
		var selectedGrid = $this.closest('[data-filter]').data('filter');

		jQuery('#' + selectedGrid).attr('data-grid-column', selectedLayout);

	});

	/*------------------------------------------------------------------------*/
	/*  chosen (plugin)
	/*------------------------------------------------------------------------*/

	if ( 'function' === typeof jQuery.fn.chosen ) {

		jQuery('.chosen-select').chosen({
			disable_search_threshold: 10
		});

	}


	/*------------------------------------------------------------------------*/
	/*  stupidtable (plugin)
	/*------------------------------------------------------------------------*/

	if ( 'function' === typeof jQuery.fn.stupidtable ) {

		jQuery( 'table' ).stupidtable();

	}


	/*------------------------------------------------------------------------*/
	/*  magnificPopup (plugin)
	/*------------------------------------------------------------------------*/

	if ( 'function' === typeof jQuery.fn.magnificPopup ) {

		// Content popup
		jQuery( '.popup-link' ).magnificPopup({
			type:'inline',
			midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
			closeMarkup: '<button title="%title%" type="button" class="mfp-close"><span class="icon"><svg role="img" class="icon-ico"><use xlink:href="#ico-close"></use></svg></span></button>'
		});


		jQuery( '.close-popup-link' ).on('click', function(event) {

			event.preventDefault();

			jQuery.magnificPopup.close();

		});

		initPopup();


		// Open popup if it has the "open" class

		jQuery( '.mfp-hide.open' ).each( function() {

			jQuery.magnificPopup.open({
				items: {
					src: jQuery(this),
					type: 'inline'
				}
			});

		} );

	}


	/*------------------------------------------------------------------------*/
	/*  tabs (plugin)
	/*------------------------------------------------------------------------*/

	if( 'function' == typeof jQuery.fn.tabs ){

		jQuery( '.tabs' ).tabs();

	}


	/*------------------------------------------------------------------------*/
	/*  select 2 - Status dropdown
	/*------------------------------------------------------------------------*/

	function formatStatus(result) {
		if (!result.id) { return result.text; }

		var formattedResult = jQuery('<span class="select2-custom-result select2-custom-result--status"><span class="status-meta status-title '+ result.element.value +'">'+ result.text +'</span></span>');

		return formattedResult;
	};

	jQuery('.select2--status').select2({
		containerCssClass       : 'select2-container--status',
		dropdownCssClass        : 'select2-dropdown--status',
		minimumResultsForSearch : Infinity,
		templateResult          : formatStatus,
		templateSelection       : formatStatus
	});



	/*------------------------------------------------------------------------*/
	/*  browser update (plugin)
	/*------------------------------------------------------------------------*/

	var $buoop = {vs:{i:8,f:25,o:12.1,s:7},c:2};
	function $buo_f(){
	 var e = document.createElement("script");
	 e.src = "//browser-update.org/update.min.js";
	 document.body.appendChild(e);
	};
	try {document.addEventListener("DOMContentLoaded", $buo_f,false)}
	catch(e){window.attachEvent("onload", $buo_f)}


	/*------------------------------------------------------------------------*/
	/*  Searching for toggles? And tabs?
	/*  - Those are initialized in ecs-shortcodes. Don't want to use the default ones? Either:
	/*  	a. Dequeue the scripts in custom.php, the script is commented in there.
	/*  	b. Use add_shortcode( 'tag', 'callback' ); to overwrite the shortcode you need.
	/*------------------------------------------------------------------------*/

});

function initPopup() {
	jQuery( '.popup-image-link' ).magnificPopup({
		type:'image',
		closeMarkup: '<button title="%title%" type="button" class="mfp-close"><span class="icon"><svg role="img" class="icon-ico"><use xlink:href="#ico-close"></use></svg></span></button>'
	});
}
